<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ file.originalname }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="odn-modal-file">
        <img
          v-if="imageTypes.includes(file.mimetype)"
          :src="file.filename"
          alt="Image"
          class="odn-modal-file-image"
          @load="onLoaded"
        />
        <video
          v-else-if="file.mimetype === 'video/mp4'"
          class="odn-modal-file-video"
          controls
          autoplay
        >
          <source :src="file.filename" type="video/mp4" />
        </video>
        <iframe
          v-else-if="file.mimetype === 'application/pdf'"
          :src="file.filename"
          class="odn-modal-file-pdf"
        ></iframe>
        <div v-else>
          <ion-button size="block" :href="file.filename" target="_blank">
            <ion-icon
              :icon="icons.cloudDownloadOutline"
              slot="start"
            ></ion-icon>
            {{ $t('buttons.download') }}
          </ion-button>
        </div>
      </div>
      <div v-if="isLoading" class="odn-modal-file-loading">
        <ion-spinner></ion-spinner>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonSpinner,
  modalController,
} from '@ionic/vue';
import { close, cloudDownloadOutline } from 'ionicons/icons';

export default {
  name: 'ModalFile',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonSpinner,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageTypes: ['image/jpeg', 'image/png', 'image/gif'],
      isLoading: false,
      icons: {
        close,
        cloudDownloadOutline,
      },
    };
  },
  async created() {
    if (this.imageTypes.includes(this.file.mimetype)) {
      this.isLoading = true;
    }
  },
  methods: {
    dismiss() {
      modalController.dismiss();
    },
    onLoaded() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.odn-modal-file {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &-image,
  &-video,
  &-pdf {
    display: block;
    width: 100%;
  }

  &-pdf {
    height: 100%;
  }
}

.odn-modal-file-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
