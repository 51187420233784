<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.filters') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ODNModalFilters>
        <ion-list>
          <ion-list-header>
            <ion-label>{{ $t('labels.sortBy') }}</ion-label>
          </ion-list-header>
          <ion-item lines="none">
            <ion-segment
              scrollable
              mode="ios"
              v-model="newFilters.sort"
              style="width: 100%"
            >
              <ion-segment-button value="originalname">
                <ion-label>{{ $t('filters.filename') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="size">
                <ion-label>{{ $t('filters.size') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="mimetype">
                <ion-label>{{ $t('filters.type') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="created">
                <ion-label>{{ $t('filters.created') }}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
          <ion-list-header>
            <ion-label>{{ $t('labels.sortDirection') }}</ion-label>
          </ion-list-header>
          <ion-item lines="none">
            <ion-segment mode="ios" v-model="newFilters.direction">
              <ion-segment-button value="asc" layout="icon-start">
                <ion-icon :icon="icons.arrowUpOutline"></ion-icon>
                <ion-label>
                  {{ $t('labels.ascending') }}
                </ion-label>
              </ion-segment-button>
              <ion-segment-button value="desc" layout="icon-start">
                <ion-icon :icon="icons.arrowDownOutline"></ion-icon>
                <ion-label>
                  {{ $t('labels.descending') }}
                </ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
        </ion-list>
        <div class="odn-modal-filters-buttons">
          <ion-button size="block" color="light" @click="reset">
            <ion-icon :icon="icons.refreshOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t('buttons.reset') }}
            </ion-label>
          </ion-button>
          <ion-button size="block" @click="dismissWithData">
            <ion-icon
              :icon="icons.checkmarkCircleOutline"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{ $t('buttons.validate') }}
            </ion-label>
          </ion-button>
        </div>
      </ODNModalFilters>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonListHeader,
  IonList,
  IonItem,
  modalController,
} from '@ionic/vue';
import {
  close,
  arrowUpOutline,
  arrowDownOutline,
  refreshOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons';

import ODNModalFilters from '@m/odn-modal-filters.vue';

export default {
  name: 'ModalStoreFilesFilters',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonListHeader,
    IonList,
    IonItem,
    ODNModalFilters,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        close,
        arrowUpOutline,
        arrowDownOutline,
        refreshOutline,
        checkmarkCircleOutline,
      },
      newFilters: {
        sort: this.filters.sort,
        direction: this.filters.direction,
      },
    };
  },
  methods: {
    reset() {
      this.newFilters = {
        sort: 'storeFile.created',
        direction: 'desc',
      };
      this.dismissWithData();
    },
    dismiss() {
      modalController.dismiss();
    },
    dismissWithData() {
      modalController.dismiss(this.newFilters);
    },
  },
};
</script>
